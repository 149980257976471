import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadSchema } from '../../../service/fetch';
import { setSchema, startOperatorSearch } from '../../../store/actions/search';
import { changeItem, resetItem } from '../../../store/actions/item';

import Button from '../../../style/Button';
import Cell from '../../../style/SearchCell';
import Container from '../../../style/Container';
import { defaultTextColor } from '../../../style/colors';
import Link from '../../../style/Link';
import { OpSearchRow as TableRow } from '../../../style/SearchRow';
import Row from '../../../style/Row';
import { SearchBody, SearchContainer } from '../../../style/Search';
import Space from '../../../style/Space';
import OpenSVG from '../../../SVG/Open';

import List from '../../../container/List';

import Loading from '../../../components/Loading';
import OperatorElement from '../../../components/OperatorElement';
import OnEnterPress from '../../../components/OnEnterPress';
import SearchFooter from '../../../components/SearchFooter';
import ZeitraumInput from '../../../components/ZeitraumInput';

import SearchItems from '../adv/items/SearchItems';

class ExternOpSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.match.params.type,
            loadingItems: false,
            showItems: false,
        };
        const { setSchema, changeItem } = this.props;
        changeItem('$operator', []);
        // get the items which can be used as proposals in fastsearch / dropdown
        loadSchema(this.props.type).then(setSchema);
    }

    async startSearch() {
        this.setState({ showItems: false });
        await this.props.startOperatorSearch();
        this.setState({ showItems: true });
    }

    componentWillUnmount() {
        this.props.resetItem();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const newType = nextProps.match.params.type;
        const { changeItem, setSchema } = nextProps;
        if (newType !== prevState.type) {
            // new type given! (User may've clicked on navigation link)
            // set default values
            changeItem('$operator', []);
            // get the items which can be used as proposals in fastsearch / dropdown
            loadSchema(newType).then(setSchema);
            // change state by returning new updated state
            return {
                ...prevState,
                type: newType,
            };
        }
        // no state change
        return null;
    }

    render() {
        const { loadingItems, proposalItems, /*count,*/ items } = this.props;
        const { showItems, type } = this.state;
        let loadingSchema = !!proposalItems ? 0 : 1;
        let searching = !loadingItems && !showItems;
        return (
            <>
                <SearchContainer>
                    <SearchBody>
                            <Container>
                                {showItems && (
                                    /*<Row>
                                        <Container>{count + ' Treffer'}</Container>
                                        <Button onClick={() => this.setState({ showItems: false })}>
                                            Suche ändern
                                        </Button>
                                    </Row>*/
                                    <Row nowrap id="SearchBar" style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
                                        <Button id="NewSearch" onClick={() => this.setState({ showItems: false })}>Suche ändern</Button>
                                        <SearchFooter pos={"top"} startSearch={startOperatorSearch} />
                                    </Row>

                                )}
                                <Row nowrap>
                                    <Loading loading={loadingSchema}>
                                        {searching ? (
                                            <>
                                                <Row nowrap>
                                                    <List label="" name={'$operator'} simple column noVerticalLine>
                                                        {index => (
                                                            <OnEnterPress
                                                                action={() =>
                                                                    this.startSearch.apply(this)
                                                                }
                                                            >
                                                                <Row>
                                                                    <OperatorElement index={index} />
                                                                </Row>
                                                            </OnEnterPress>
                                                        )}
                                                    </List>
                                                </Row>
												<Row nowrap>
													<Space/>
													{type === 'brief' && (
		                                               <ZeitraumInput name="$period" />
													)}
													<Space/>
													<Button onClick={() => this.startSearch()}>
		                                            	Suchen
		                                        	</Button>
												</Row>	
                                                <Row>
                                                    <Space/>
                                                    <Link to={`/attributes/${type}`} target="_self" color={defaultTextColor}>
                                                        <Button>
                                                            	➔ Zur Standardsuche
                                                        </Button>
                                                    </Link>
                                                </Row>
                                            </>
                                        ) : (
                                            <SearchItems
                                                items={items}
                                                loading={loadingItems}
                                            >
                                                {(item, i) => (
													type === 'person' ? (
	                                                    <TableRow key={i}>
	                                                        <Link
	                                                            to={`/edit/${type}/${item._id}`}
	                                                            target={false}
	                                                        >
	                                                            <OpenSVG />
	                                                        </Link>
	                                                        <Cell>{item.short.v}</Cell> {/* für Personen */}
	                                                    </TableRow>
													) : (
														<TableRow key={i}>
	                                                        <Link
	                                                            to={`/edit/${type}/${item._id}`}
	                                                            target={false}
	                                                        >
	                                                            <OpenSVG />
	                                                        </Link>
                                                        	<Cell>{item.long.v}</Cell> {/* für Briefe etc. */}
	                                                    </TableRow>
													)
                                                )}
                                            </SearchItems>
                                        )}
                                    </Loading>
                                </Row>
                            </Container>
                    </SearchBody>
                </SearchContainer>
                {showItems && <SearchFooter op startSearch={startOperatorSearch} />}
            </>
        );
    }
}

const mapStateToProps = (state, props) => ({
    proposalItems: state.search.schema,
    items: state.search.items,
    count: state.search.count,
    type: props.match.params.type,
    loadingItems: state.search.loading,
});

const mapDispatchToProps = {
    changeItem,
    resetItem,
    setSchema,
    startOperatorSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternOpSearch);
