import {
    ADD_ARRAY_ELEMENT,
    BUILD_SHORT_SACHE,
    CHANGE_ITEM,
    CHANGE_ITEM_FAV,
    CREATE_ITEM,
    DELETE_ARRAY_ELEMENT,
    LOAD_ITEM,
    LOAD_LOG,
    MOVE_ARRAY_ELEMENT_DOWN,
    MOVE_ARRAY_ELEMENT_UP,
    RESET_ITEM,
    SAVE_ITEM,
} from '../constants/item';
import { get, set } from '../../service/immutables';
import { buildSacheShort } from '../../service/shorts';

export default function item(state = { saved: true }, action) {
    let items, item;
    switch (action.type) {
        case CREATE_ITEM:
        case SAVE_ITEM:
        case LOAD_ITEM:
            action.item.saved = true;
            return action.item;
        case LOAD_LOG:
            if (action.kind) {
                item = set(action.kind, action.log, state.log);
            } else {
                item = action.log;
            }
            return {
                ...state,
                log: item,
            };
        case CHANGE_ITEM:
            item = set(action.name, action.value, state);
            item.saved = action.save || false;
            return item;
        case CHANGE_ITEM_FAV:
            item = set(action.name, action.value, state);
            return item;
        case RESET_ITEM:
            return {};
        case DELETE_ARRAY_ELEMENT:
            // its important that a new array is create (filter does it) cause of not mutating state rule
            items = get(action.name, state).filter((_, i) => i !== action.index);
            item = set(action.name, items, state);
            item.saved = false;
            return item;
        case ADD_ARRAY_ELEMENT:
            // not mutating state means that a new array is created
            // check if the array is existing because otherwise you can spread undefine
            items = get(action.name, state) ? [...get(action.name, state), {}] : [{}];
            item = set(action.name, items, state);
            item.saved = false;
            return item;
        case MOVE_ARRAY_ELEMENT_UP:
            items = get(action.name, state);
            // the first elem should be moved into position items.length - 1 #611
            if (action.index === 0) {
                let first = items.shift();
                items.push(first);
            } else {
                // map should do the shallow copy
                items = items.map(function(element, index, items) {
                    if (index === action.index - 1) {
                        return items[action.index];
                    } else if (index === action.index) {
                        return items[action.index - 1];
                    } else {
                        return element;
                    }
                });
            }
            item = set(action.name, items, state);
            item.saved = false;
            return item;
        case MOVE_ARRAY_ELEMENT_DOWN:
            items = get(action.name, state);
            // the last elem should be moved into position 0 #611
            if (action.index === items.length - 1) {
                let last = items.pop();
                items.unshift(last);
            } else {
                // map should do the shallow copy
                items = items.map(function(element, index, items) {
                    if (index === action.index + 1) {
                        return items[action.index];
                    } else if (index === action.index) {
                        return items[action.index + 1];
                    } else {
                        return element;
                    }
                });
            }
            item = set(action.name, items, state);
            item.saved = false;
            return item;

        case BUILD_SHORT_SACHE:
            // not mutating state means that a new array is created
            return set('begriff.v', buildSacheShort(state), state);
        default:
            return state;
    }
}
