import React from 'react';
import ReactWordcloud from 'react-wordcloud';

const options = {
	rotations: 0,
	enableTooltip: false,
	fontSizes: [14,30],
	fontStyle: "normal",
  	fontWeight: "normal",
}

const wordCloudAktuell = [
	{text: 'Studenten',	value: 4,},
	{text: 'Delikte',	value: 3,},	
	{text: 'Karzer',	value: 2,},	
	{text: 'Stipendiaten',	value: 2,},
	{text: 'Essen auf der Erde',	value: 1,},	
	{text: 'Bestrafung',	value: 2,},	
	{text: 'Tübinger Stift', value: 1,},
]

function SimpleWordcloud() {return <ReactWordcloud words={wordCloudAktuell} options={options} maxWords="9"/>}

class WordCloud extends React.Component {
    render() {
        return (
				<>{SimpleWordcloud()}</>
        );
    }
}

/*

const wordCloudJuli2024 = [
	{text: 'Medizin',	value: 4,},
	{text: 'Sulzer',	value: 3,},	
	{text: 'Marbach',	value: 3,},	
	{text: 'Milch',	value: 2,},
	{text: 'Kräuter',	value: 2,},	
	{text: 'Honig',	value: 2,},	
	{text: 'Magenschmerzen', value: 1,},
]

const wordCloudJuni2024 = [
	{text: 'Wein',	value: 5,},
	{text: 'Handel',	value: 4,},	
	{text: 'Transport',	value: 3,},	
	{text: 'Fässer',	value: 3,},
	{text: 'Bezahlung',	value: 2,},	
	{text: 'Reichenweier',	value: 1,},
	{text: 'Straßburg',	value: 1,},	
]

const wordCloudMai2024 = [
	{text: 'Flacius',	value: 5,},
	{text: 'Teufel',	value: 4,},	
	{text: 'Tod',	value: 3,},	
	{text: 'Andreae',	value: 3,},
	{text: 'Polemik',	value: 2,},	
	{text: 'Erbsünde',	value: 1,},
	{text: 'De mortuis nil nisi bene',	value: 1,},	
]

const wordCloudApr2024 = [
	{text: 'Umzug',	value: 5,},
	{text: 'Tübinger Stift',	value: 4,},	
	{text: 'Esslingen',	value: 3,},	
	{text: 'Pest',	value: 3,},
	{text: '1571',	value: 2,},	
	{text: 'Hausrat',	value: 1,},
	{text: 'Brennholz',	value: 1,},	
]

const wordCloudMrz2024 = [
	{text: 'Pest',	value: 5,},
	{text: 'Heidelberg',	value: 4,},	
	{text: '1564',	value: 3,},	
	{text: 'Infektion',	value: 2,},
	{text: 'Kirchhof',	value: 2,},	
	{text: 'Begräbnis',	value: 1,},
	{text: 'Peterskirche',	value: 1,},	
]

const wordCloudFeb2024 = [
	{text: 'Sekretär',	value: 4,},		
	{text: 'Sprachen',	value: 5,},
	{text: 'England',	value: 3,},	
	{text: 'Grindal',	value: 3,},
	{text: 'Straßburg',	value: 2,},	
	{text: 'Blarer',	value: 1,},
	{text: 'Hubert',	value: 1,},	
]

const wordCloudJan2024 = [
	{text: 'Heidelberg',	value: 5,},		
	{text: 'Patiens',	value: 4,},
	{text: '1579',	value: 3,},	
	{text: 'Frevel',	value: 3,},
	{text: 'Restauration',	value: 2,},	
	{text: 'Calvinisten',	value: 1,},
	{text: 'Lutheraner',	value: 1,},	
]

const wordCloudDez2023 = [
	{text: 'Studenten',	value: 4,},		
	{text: 'Lästerung',	value: 3,},
	{text: 'Calvinianer',	value: 3,},	
	{text: 'Karzer',	value: 3,},
	{text: 'Stadt',	value: 2,},	
	{text: 'Universität',	value: 2,},
	{text: 'Gerichtsbarkeit',	value: 1,},	
]

const wordCloudNov2023 = [
	{text: 'Alchemie',	value: 5,},	
	{text: 'Pappus',	value: 3,},
	{text: 'Trinkgold',	value: 1,},
	{text: 'Straßburg',	value: 3,},
	{text: 'Arznei',	value: 4,},
	{text: 'Bibel',	value: 1,},
	{text: 'Marbach',	value: 2,},
]

const wordCloudOkt2023 = [
	{text: 'Abort',	value: 4,},	
	{text: 'Erker',	value: 4,},
	{text: 'Geruch',	value: 3,},
	{text: 'Schnepf',	value: 2,},
	{text: 'Strigel',	value: 2,},
	{text: 'Wohnung',	value: 1,},
	{text: 'Abwasser',	value: 1,},
]

const wordCloudSept2023 = [
	{text: 'London',	value: 5,},	
	{text: 'Studienort',	value: 4,},
	{text: 'Frankreich',	value: 3,},
	{text: 'Italien',	value: 3,},
	{text: 'Tossanus',	value: 2,},
	{text: 'Elisabeth I. Tudor',	value: 2,},
	{text: 'Graf von Wittgenstein',	value: 1,},
]

const wordCloudAug2023 = [
	{text: 'Tübingen',	value: 4,},
	{text: 'Franzosen',	value: 4,},
	{text: 'Immatrikulation',	value: 3,},
	{text: 'Rektor',	value: 2,},
	{text: 'Universität',	value: 2,},
	{text: 'Auslandsstudium',	value: 1,},
	{text: 'Studenten',	value: 1,},
]
const wordCloudJuli2023 = [
	{text: 'Träume',	value: 4,},
	{text: 'Melanchthon',	value: 4,},	
	{text: 'Deutung',	value: 3,},
	{text: 'Prophetie',	value: 2,},
	{text: 'Dan 7',	value: 2,},
	{text: 'Karl V.',	value: 1,},
	{text: 'Schmalkaldischer Krieg',	value: 1,},
]
const wordCloudJuni2023 = [
	{text: 'Geschichte',	value: 5,},
	{text: 'Bücher',	value: 4,},
	{text: 'Sleidanus',	value: 3,},
	{text: 'Lautenbach',	value: 3,},
	{text: 'Zeitzeuge',	value: 2,},
	{text: 'Historiographie',	value: 2,},
	{text: 'Unparteilichkeit',	value: 1,},
]
const wordCloudMai2023 = [
	{text: 'Mord',	value: 5,},
	{text: 'Explosion',	value: 4,},
	{text: 'Henry Stuart',	value: 3,},
	{text: 'Mary Stuart',	value: 3,},
	{text: 'Guise',	value: 2,},
	{text: 'Erastus',	value: 1,},
	{text: 'Edinburgh',	value: 1,},
]
const wordCloudApril2023 = [
	{text: 'Neustadt',	value: 5,},
	{text: 'Wandern',	value: 4,},
	{text: 'Zanchi',	value: 3,},
	{text: 'Casimiranum',	value: 1,},
	{text: 'Berge',	value: 1,},
	{text: 'Hügel',	value: 1,},
	{text: 'Heidelberg',	value: 1,},
]
const wordCloudMärz2023 = [
	{text: 'Astronomie',	value: 5,},
	{text: 'Universität Tübingen',	value: 1,},
	{text: 'Himmelsglobus',	value: 3,},
	{text: 'Pest',	value: 4,},
	{text: 'Instrument',	value: 1,},
	{text: 'Stipendiaten',	value: 1,},
	{text: 'Esslingen',	value: 1,},
]
const wordCloudFebruar2023 = [
	{text: '10.000. Brief',	value: 4,},
	{text: 'Nachrichten',	value: 2,},
	{text: 'Oberpfalz',	value: 2,},
	{text: 'Frankreich',	value: 1,},
	{text: 'Heidelberger Katechismus',	value: 1,},
	{text: 'Briefbeförderung',	value: 1,},
]
const wordCloudJanuar2023 = [
	{text: 'Zanchi',	value: 5,},
	{text: 'Umzug',	value: 4,},
	{text: 'Audienz',	value: 3,},
	{text: 'Heidelberg', value: 3,},
	{text: 'Begrüßung',	value: 2,},
	{text: 'Familie',	value: 1,},
	{text: 'Reisekosten',	value: 1,},
]
const wordCloudDezember2022 = [
	{text: 'Erdbeben',	value: 5,},
	{text: 'Brand',	value: 5,},	
	{text: 'Frankreich',	value: 4,},
	{text: 'Wien',	value: 4,},
	{text: 'Heidelberg', value: 4,},
	{text: 'Strafe Gottes',	value: 2,},
	{text: '8. Hugenottenkrieg',	value: 1,},
]
const wordCloudNovember2022 = [
	{text: 'Pareus',	value: 5,},
	{text: 'Eheschließung',	value: 4,},
	{text: 'Stibelius',	value: 3,},
	{text: 'Ursinus', value: 3,},
	{text: 'Einladung',	value: 2,},
	{text: 'Sittlichkeit',	value: 1,},	
	{text: 'Pfarrerhochzeit',	value: 1,},
]
const wordCloudOktober2022 = [
	{text: 'Morata',	value: 5,},
	{text: 'Flinner',	value: 4,},
	{text: 'Gedicht', value: 3,},
	{text: 'Psalm',	value: 3,},	
	{text: 'Italien',	value: 2,},
	{text: 'Griechisch',	value: 1,},
	{text: 'Humanismus',	value: 1,},
]
const wordCloudSeptember2022 = [
	{text: 'Brenz d. J.',	value: 5,},
	{text: 'Berufung',	value: 4,},
	{text: 'Prälatur',	value: 3,},
	{text: 'Professur',	value: 3,},	
	{text: 'Alpirsbach',	value: 2,},
	{text: 'Tübingen',	value: 1,},
	{text: 'Karriere',	value: 1,},
]
const wordCloudAugust2022 = [
	{text: 'Zanchi',	value: 5,},
	{text: 'Handschrift',	value: 3,},
	{text: 'Ursinus',	value: 2,},
	{text: 'Crato',	value: 2,},	
	{text: 'Korrespondenz',	value: 1,},
	{text: 'Unleserlichkeit',	value: 1,},
]
const wordCloudJuli2022 = [
	{text: 'Käse',	value: 5,},
	{text: 'Erastus',	value: 4,},
	{text: 'Grynaus',	value: 3,},	
	{text: 'Einkauf',	value: 3,},
	{text: 'Transport',	value: 2,},
	{text: 'Kaufleute',	value: 1,},
	{text: 'Straßburg',	value: 1,},
]
const wordCloudJuni2022 = [
	{text: 'Italien',	value: 5,},
	{text: 'Kurpfalz',	value: 3,},
	{text: 'Täufer',	value: 2,},	
	{text: 'Flinner',	value: 2,},
	{text: 'Visitation',	value: 1,},
	{text: 'Obrigkeit',	value: 1,},
]
const wordCloudMai2022 = [
	{text: 'Studenten',	value: 4,},
	{text: 'Gewalt',	value: 3,},
	{text: 'Würfelspiel',	value: 3,},	
	{text: 'Edo Hilderich',	value: 2,},
	{text: 'Kostgänger',	value: 2,},		
	{text: 'Universität Altdorf',	value: 1,},	
	{text: 'Vorlesungen',	value: 1,},
]
const wordCloudApril2022 = [
	{text: 'Grynaeus',	value: 4,},
	{text: 'Chronogramm',	value: 3,},
	{text: 'Rätsel',	value: 3,},
	{text: 'Humanismus',	value: 2,},	
	{text: 'Psalmen',	value: 2,},		
	{text: 'Geist',	value: 1,},	
	{text: 'Fleisch',	value: 1,},
]
const wordCloudMärz2022 = [
	{text: 'Supernova',	value: 4,},
	{text: 'Stern',	value: 4,},	
	{text: 'Jüngster Tag',	value: 3,},	
	{text: 'Geduld',	value: 2,},		
	{text: 'Frieden',	value: 2,},
	{text: 'Andreae',	value: 2,},
	{text: 'Herzogin',	value: 1,},	
	{text: 'Braunschweig-Wolfenbüttel',	value: 1,},
]
const wordCloudFebruar2022 = [
	{text: 'Andreae',	value: 4,},
	{text: 'Familie',	value: 3,},	
	{text: 'Ehefrau',	value: 3,},	
	{text: 'Kinder',	value: 3,},		
	{text: 'Tod',	value: 2,},
	{text: 'Krankheit',	value: 2,},
	{text: 'Reisen',	value: 1,},	
	{text: 'Konkordie',	value: 1,},
]
const wordCloudJanuar2022 = [
	{text: 'Komet',	value: 5,},
	{text: 'Sternbild',	value: 3,},
	{text: 'Heiden',	value: 2,},	
	{text: 'Strafe Gottes',	value: 2,},
	{text: 'Zacharias Ursinus',	value: 1,},
	{text: 'Johannes Brenz',	value: 1,},
]
const wordCloudDezember2021 = [
	{text: 'Heerbrand',	value: 4,},	
	{text: 'Herdesianus',	value: 3,},
	{text: 'Papisten',	value: 3,},	
	{text: 'Consensus orthodoxus',	value: 2,},	
	{text: 'Universität Tübingen',	value: 2,},		
	{text: 'Arbeitsbelastung',	value: 1,},
	{text: 'Jesuiten',	value: 1,},
]
const wordCloudNovember2021 = [
	{text: 'Heidelberg',	value: 4,},
	{text: 'Auditorium',	value: 3,},
	{text: 'Vandalismus',	value: 3,},
	{text: 'Zanchi',	value: 2,},
	{text: 'Universitätsarchiv',	value: 2,},
	{text: 'Vorlesungen',	value: 1,},
	{text: 'Theologische Fakultät',	value: 1,},
]
const wordCloudOktober2021 = [
	{text: 'Pass',	value: 4,},
	{text: 'Reise',	value: 4,},
	{text: 'Gerlach',	value: 3,},
	{text: 'Urkunde',	value: 2,},
	{text: 'Wien',	value: 2,},
	{text: 'Gesandtschaft',	value: 1,},
	{text: 'Konstantinopel',	value: 1,},
]
const wordCloudSeptember2021 = [
	{text: 'Andreae',	value: 3,},
	{text: 'Badekur',	value: 4,},
	{text: 'Humoralpathologie',	value: 1,},
	{text: 'Schwindelanfall',	value: 1,},
	{text: 'Arzneimittel',	value: 1,},
	{text: 'Medizin',	value: 1,},
	{text: 'Wildbad',	value: 2,},
]
const wordCloudAugust2021 = [
	{text: 'Hausschüler',	value: 4,},
	{text: 'Wittenberg',	value: 3,},
	{text: 'Erziehung',	value: 2,},
	{text: 'Student',	value: 2,},
	{text: 'Zorn Gottes',	value: 2,},
	{text: 'Zügellosigkeit',	value: 2,},
	{text: 'Eltern',	value: 1,},
	{text: 'Disziplin',	value: 1,},
	{text: 'Kerkerhaft',	value: 1,},
]
const wordCloudJuli2021 = [
	{text: 'Disputation',	value: 4,},
	{text: 'Streit',	value: 3,},
	{text: 'Universität Heidelberg',	value: 2,},
	{text: 'Rektor',	value: 2,},
	{text: 'Statuten',	value: 2,},
	{text: 'Beleidigung',	value: 2,},
	{text: 'Mediziner',	value: 1,},
	{text: 'Theologen',	value: 1,},
	{text: 'Affekt',	value: 1,},
]
const wordCloudJuni2021 = [
	{text: 'Schulden',	value: 4,},
	{text: 'Pfarrer',	value: 3,},
	{text: 'Darlehen',	value: 3,},
	{text: 'Bücher',	value: 2,},
	{text: 'Vorschuss',	value: 2,},
	{text: 'Straßburger Akademie',	value: 1,},
	{text: 'Witwe',	value: 1,},
	{text: 'Zinsen',	value: 1,},
	{text: 'Gulden',	value: 1,},
]
const wordCloudMai2021 = [
	{text: 'Empfehlung',	value: 4,},
	{text: 'Straßburg',	value: 3,},
	{text: 'Körpermaße',	value: 2,},
	{text: 'Schulmeister',	value: 1,},
	{text: 'Studium',	value: 1,},
	{text: 'Regensburger Reichstag',	value: 1,},
	{text: 'Collegium Wilhelmitanum',	value: 1,},
]
const wordCloudApril2021 = [
	{text: 'Student',	value: 4,},
	{text: 'Trunkenheit',	value: 4,},
	{text: 'Strafe',	value: 2,},
	{text: 'Wein',	value: 1,},
	{text: 'Zechen',	value: 1,},
	{text: 'Tübinger Stift',	value: 1,},
	{text: 'Gefängnis',	value: 1,},
]
const wordCloudMaerz2021 = [
	{text: 'Bücher',	value: 4,},	
	{text: 'Hund',	value: 4,},
	{text: 'Reise',	value: 3,},	
	{text: 'Vergil',	value: 2,},
	{text: 'Christoph Herdesian',	value: 2,},
	{text: 'Hieronymus Bock',	value: 2,},	
	{text: 'Haustier',	value: 1,},
	{text: 'Verlust',	value: 1,},
	{text: 'Tierliebe',	value: 1,},
]
const wordCloudFeb2021 = [
	{text: 'Pest',	value: 5,},	
	{text: 'Universität',	value: 3,},
	{text: 'Tübingen',	value: 3,},
	{text: 'Esslingen',	value: 3,},	
	{text: 'Ernte',	value: 2,},
	{text: 'Tübinger Stift',	value: 2,},	
	{text: 'Gemüse',	value: 1,},
	{text: 'Hausrat',	value: 1,},
	{text: 'Weinlese',	value: 1,},
]
const wordCloudJan2021 = [
	{text: 'Haus',	value: 5,},	
	{text: 'Hauskauf',	value: 4,},
	{text: 'Familie',	value: 3,},
	{text: 'Freundschaft',	value: 2,},
	{text: 'Geld',	value: 2,},
	{text: 'Mäzen',	value: 1,},
	{text: 'Küche',	value: 1,},
	{text: 'Pfrundhaus',	value: 1,},
	{text: 'Ratenkauf',	value: 1,},
]
*/

export default WordCloud;
